section {
  padding-top: 25px;
  padding-bottom: 25px;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

input,
textarea {
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

button {
  background-color: #27b3e3;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

footer {
  background-color: #27b3e3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.App-header {
  background-color: #27b3e3;
  color: white;
  padding: 3rem 0;
}

.Info-section,
.Contact-section {
  margin: 2rem 0;
}

.Navbar {
  background-color: #27b3e3;
  color: white;
  /* position: fixed; */
  top: 0;
  width: 100%;
  /* z-index: 1000; */
}

.Navbar ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
}

.Navbar li {
  margin: 0 1rem;
}